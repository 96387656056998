<template>
  <div id="user-view">
    <v-row>
      <!-- User bio panel -->
      <v-col
        cols="12"
        md="5"
        lg="4"
      >
        <user-bio-panel
          :user-data="userData"
          :is-plan-upgrade-dialog-visible.sync="isPlanUpgradeDialogVisible"
          @refetch-data="getUserData"
        ></user-bio-panel>
      </v-col>

      <!-- User data panels and tabs -->
      <v-col
        cols="12"
        md="7"
        lg="8"
      >
        <v-tabs
          v-model="userTab"
          show-arrows
          class="user-tabs"
        >
          <v-tab
            v-for="tab in tabs"
            :key="tab.icon"
          >
            <v-icon
              size="20"
              class="me-3"
            >
              {{ tab.icon }}
            </v-icon>
            <span>{{ tab.title }}</span>
          </v-tab>
        </v-tabs>

        <v-tabs-items
          id="user-tabs-content"
          v-model="userTab"
          class="mt-5 pa-1"
        >
          <v-tab-item>
            <user-tab-class
              :user-data="userData"
              @refetch-data="getUserData"
            >
            </user-tab-class>
          </v-tab-item>
          <v-tab-item>
            <user-tab-login-method
              :user-data="userData"
              @refetch-data="getUserData"
            >
            </user-tab-login-method>
          </v-tab-item>
        </v-tabs-items>
      </v-col>
    </v-row>
  </div>
</template>

<script>
// eslint-disable-next-line import/no-cycle
import router from '@/router'
import store from '@/store'
import { onUnmounted, ref } from '@vue/composition-api'

// eslint-disable-next-line object-curly-newline
import {
  mdiAccountGroupOutline,
  mdiDoorClosedLock,
  mdiFormTextboxPassword,
} from '@mdi/js'
import ComponentUserLoginDetails from '../../../components/users/loginDetails.vue'
import userStoreModule from '../userStoreModule'
import UserBioPanel from './user-bio-panel/UserBioPanel.vue'
import UserTabClass from './user-tab-class/UserTabClass.vue'
import UserTabLoginMethod from './user-tab-login-method/UserTabLoginMethod.vue'

export default {
  components: {
    UserBioPanel,
    UserTabClass,
    UserTabLoginMethod,
    ComponentUserLoginDetails,
  },
  setup() {
    const isUserTabClassAddOpen = ref(false)
    const USER_APP_STORE_MODULE_NAME = 'app-user'

    // Register module
    if (!store.hasModule(USER_APP_STORE_MODULE_NAME)) store.registerModule(USER_APP_STORE_MODULE_NAME, userStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(USER_APP_STORE_MODULE_NAME)) store.unregisterModule(USER_APP_STORE_MODULE_NAME)
    })

    const userData = ref({})
    const userTab = ref(null)
    const isPlanUpgradeDialogVisible = ref(false)

    const getUserData = async () => {
      console.log('getUserData')
      store
        .dispatch('app-user/fetchUser', { id: router.currentRoute.params.id })
        .then(response => {
          console.log('response', response.data)
          userData.value = response.data.teacher
        })
        .catch(error => {
          console.log('error fetching teacher', error)
          if (error.response.status === 404) {
            userData.value = {}
          }
        })
    }
    getUserData()

    const plansList = [
      { text: 'Basic - $0/month', value: 'basic' },
      { text: 'Standard - $99/month', value: 'standard' },
      { text: 'Enterprise - $499/month', value: 'enterprise' },
      { text: 'Company - $999/month', value: 'company' },
    ]

    const tabs = [
      { icon: mdiAccountGroupOutline, title: 'Klassen' },
      { icon: mdiDoorClosedLock, title: 'Inlogmethode' },

      // { icon: mdiLockOutline, title: 'Security' },
      // { icon: mdiBookmarkOutline, title: 'Billing & Plan' },
      // { icon: mdiBellOutline, title: 'Notifications' },
      // { icon: mdiLinkVariant, title: 'Connections' },
    ]

    // ui
    const resolveCurrentPlanValue = plan => {
      if (plan === 'basic') return '0'
      if (plan === 'standard') return '99'
      if (plan === 'enterprise') return '499'
      if (plan === 'company') return '999'

      return '0'
    }

    return {
      getUserData,
      tabs,
      userTab,
      plansList,
      isPlanUpgradeDialogVisible,
      userData,
      resolveCurrentPlanValue,
    }
  },
}
</script>

<style lang="scss">
@import '@core/preset/preset/apps/user.scss';
</style>
