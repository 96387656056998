<template>
  <v-row class="user-bio-panel">
    <!-- user profile -->
    <v-col cols="12">
      <v-card class="pt-10">
        <v-card-title class="justify-center flex-column">
          <span class="mb-5">{{ userData.firstName + ' ' + userData.lastName }}</span>
        </v-card-title>

        <v-card-actions class="justify-center">
          <v-btn
            color="primary"
            class="me-3"
            block
            @click="isBioDialogOpen = !isBioDialogOpen"
          >
            Bewerken
          </v-btn>
        </v-card-actions>
      </v-card>

      <!-- edit profile dialog data -->
      <user-bio-edit
        :is-bio-dialog-open.sync="isBioDialogOpen"
        :user-data="userData"
        :login-methods="loginMethods"
        :classes="classes"
        v-on="$listeners"
      ></user-bio-edit>
    </v-col>
  </v-row>
</template>

<script>
import axios from '@axios'
import { avatarText, kFormatter } from '@core/utils/filter'
import {
mdiAccountGroup, mdiBriefcaseVariantOutline, mdiCheck, mdiCheckboxBlankCircle
} from '@mdi/js'
import { ref } from '@vue/composition-api'
import UserBioEdit from './UserBioEdit.vue'

export default {
  components: {
    UserBioEdit,
  },
  props: {
    userData: {
      type: Object,
      required: true,
    },
    isPlanUpgradeDialogVisible: {
      type: Boolean,
      required: true,
    },
  },
  setup(props, { emit }) {
    const isBioDialogOpen = ref(false)

    console.log(props.userData)

    // Login methods
    const loginMethods = ref([])
    axios.get('/loginmethod').then(response => {
      loginMethods.value = response.data.loginMethods
    })

    const school = JSON.parse(localStorage.getItem('school'))

    // Classes
    const classes = ref([])
    axios.get(`/school/${school.id}/class`).then(response => {
      const preparedClasses = response.data.classes.map(classItem => ({
        value: classItem.id,
        title: classItem.name,
      }))
      classes.value = [
        {
          value: 'none',
          title: 'Geen',
          disabled: true,
        },
        ...preparedClasses,
      ]
    })

    return {
      avatarText,
      kFormatter,

      loginMethods,
      classes,

      isBioDialogOpen,
      icons: {
        mdiCheck,
        mdiBriefcaseVariantOutline,
        mdiCheckboxBlankCircle,
        mdiAccountGroup,
      },
    }
  },
}
</script>
