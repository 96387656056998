import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"user-tab-overview"},[_c(VCard,{staticClass:"mb-7"},[_c(VCardTitle,[_vm._v(" Klassen "),_c(VSpacer),_c(VBtn,{attrs:{"color":"primary"},on:{"click":function($event){_vm.isAddClassDialogOpen = true}}},[_vm._v(" Klas toevoegen ")])],1),_c(VDataTable,{attrs:{"headers":_vm.tableColumnHeaders,"items":_vm.userData.classes,"no-data-text":"Geen klassen gevonden","hide-default-footer":""},scopedSlots:_vm._u([{key:"item.name",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(" "+_vm._s(item.name)+" ")])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c(VMenu,{attrs:{"bottom":"","left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c(VBtn,_vm._g(_vm._b({attrs:{"icon":""}},'v-btn',attrs,false),on),[_c(VIcon,[_vm._v(_vm._s(_vm.icons.mdiDotsVertical))])],1)]}}],null,true)},[_c(VList,[_c(VListItem,{on:{"click":function($event){return _vm.onBtnDeleteClassClick(item)}}},[_c(VListItemTitle,[_c(VIcon,{staticClass:"me-2",attrs:{"size":"20"}},[_vm._v(" "+_vm._s(_vm.icons.mdiDeleteOutline)+" ")]),_c('span',[_vm._v("Verwijderen")])],1)],1)],1)],1)]}}],null,true)})],1),_c('user-add-class',_vm._g({attrs:{"is-add-class-dialog-open":_vm.isAddClassDialogOpen,"class-list":_vm.classes,"user-data":_vm.userData},on:{"update:isAddClassDialogOpen":function($event){_vm.isAddClassDialogOpen=$event},"update:is-add-class-dialog-open":function($event){_vm.isAddClassDialogOpen=$event}}},_vm.$listeners))],1)}
var staticRenderFns = []

export { render, staticRenderFns }