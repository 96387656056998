<template>
  <div class="user-tab-overview">
    <!-- user class list -->
    <v-card class="mb-7">
      <v-card-title>
        Klassen
        <v-spacer></v-spacer>
        <v-btn
          color="primary"
          @click="isAddClassDialogOpen = true"
        >
          Klas toevoegen
        </v-btn>
      </v-card-title>

      <v-data-table
        :headers="tableColumnHeaders"
        :items="userData.classes"
        no-data-text="Geen klassen gevonden"
        hide-default-footer
      >
        <!-- project -->
        <template #[`item.name`]="{item}">
          <span>
            {{ item.name }}
          </span>
        </template>

        <!-- actions -->
        <template #[`item.actions`]="{item}">
          <v-menu
            bottom
            left
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                icon
                v-bind="attrs"
                v-on="on"
              >
                <v-icon>{{ icons.mdiDotsVertical }}</v-icon>
              </v-btn>
            </template>

            <v-list>
              <v-list-item
                @click="onBtnDeleteClassClick(item)"
              >
                <v-list-item-title>
                  <v-icon
                    size="20"
                    class="me-2"
                  >
                    {{ icons.mdiDeleteOutline }}
                  </v-icon>
                  <span>Verwijderen</span>
                </v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </template>
      </v-data-table>
    </v-card>
    <!-- User add class dialog -->
    <user-add-class
      :is-add-class-dialog-open.sync="isAddClassDialogOpen"
      :class-list="classes"
      :user-data="userData"
      v-on="$listeners"
    ></user-add-class>
  </div>
</template>

<script>
import axios from '@axios'
import { mdiDeleteOutline, mdiDotsVertical } from '@mdi/js'
import { ref } from '@vue/composition-api'
import UserAddClass from './UserTabClassAdd.vue'

export default {
  components: {
    UserAddClass,
  },
  props: {
    userData: {
      type: Object,
      required: true,
    },
  },
  setup(props, { emit }) {
    const isAddClassDialogOpen = ref(false)

    const school = JSON.parse(localStorage.getItem('school'))

    // Classes
    const classes = ref([])
    axios.get(`/school/${school.id}/class?simple=true`).then(response => {
      const preparedClasses = response.data.classes.map(classItem => ({
        value: classItem.id,
        title: classItem.name,
      }))
      classes.value = [
        {
          value: 'none',
          title: 'Geen',
          disabled: true,
        },
        ...preparedClasses,
      ]
    })

    const onBtnDeleteClassClick = async item => {
      const response = await axios.delete(`/school/${school.id}/teacher/${props.userData.id}/class/${item.id}`)
      emit('refetch-data')
    }

    const tableColumnHeaders = [
      {
        text: 'KLAS',
        value: 'name',
        sortable: false,
      }, {
        text: 'ACTIES',
        value: 'actions',
        align: 'center',
        sortable: false,
      },
    ]

    return {
      classes,
      isAddClassDialogOpen,
      tableColumnHeaders,
      onBtnDeleteClassClick,
      icons: {
        mdiDotsVertical,
        mdiDeleteOutline,
      },
    }
  },
}
</script>

<style lang="scss" scoped>
.project-progress {
  min-width: 4rem;
}
</style>
