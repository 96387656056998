import axios from '@axios'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchUsers(ctx, queryParams) {
      const school = JSON.parse(localStorage.getItem('school'))

      return new Promise((resolve, reject) => {
        axios
          .get(`/school/${school.id}/teacher`, { params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchUser(ctx, { id }) {
      const school = JSON.parse(localStorage.getItem('school'))

      return new Promise((resolve, reject) => {
        axios
          .get(`/school/${school.id}/teacher/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    addUser(ctx, userData) {
      return new Promise((resolve, reject) => {
        axios
          .post('/account', userData)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    deleteUser(ctx, userData) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`/account/${userData.id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    addUserAsTeacher(ctx, userData) {
      const school = JSON.parse(localStorage.getItem('school'))

      return new Promise((resolve, reject) => {
        console.log('gonna add user as teacher', userData)
        axios
          .post(`/school/${school.id}/teacher/${userData.id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    addUserToSchool(ctx, { userData }) {
      const school = JSON.parse(localStorage.getItem('school'))

      return new Promise((resolve, reject) => {
        axios
          .post(`/school/${school.id}/teacher`, {
            accountId: userData.id,
          })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  },
}
